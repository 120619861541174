import { Route, NavigationGuardNext } from 'vue-router';
import store from '@/store';
import { serverData } from '@/infrastructure/config/serverData';
import { ServiceMode } from '@/infrastructure/config/serviceMode';
import { SellerService } from '@/services/sellers/types';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
    const requiresOneOfServices: SellerService[] | undefined = to?.meta?.requiresOneOfServices;

    if (!requiresOneOfServices || requiresOneOfServices.length === 0) {
        next();

        return;
    }

    const activeServices: SellerService[] = store().getters['auth/getActiveServices'];

    const matchBetweenRequiredServicesAndActiveServices = requiresOneOfServices.some((requiredService) =>
        activeServices.includes(requiredService)
    );

    if (matchBetweenRequiredServicesAndActiveServices) {
        next();

        return;
    }

    if (serverData.serviceMode === ServiceMode.COMBINED) {
        const mainProfilePageAlias = '/phw/my-account/profile';
        next(mainProfilePageAlias);
    } else {
        const mainProfilePage = 'my-account/profile';
        next({ name: mainProfilePage, params: { ...to.params } });
    }
};
