import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter, { RouteConfig } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import AbstractView from '@/pages/AbstractView.vue';

import {
    loadSellerLanguageLocales,
    loadPreferredLanguageLocale,
    isUserLoggedIn,
    isUserAllowed,
    checkConsentForTracking,
    isBusinessUnitValid,
    isRoutePendingToMigrate,
    handleZendeskWebWidget
} from '@/infrastructure/navigationGuards';
import { loadTours } from '@/infrastructure/navigationHooks/loadTours';
import { serverData } from '@/infrastructure/config/serverData';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';

import { getMarketplaceRoutes } from './marketplaceRoutes';
import { getMyAccountRoutes } from './myAccountRoutes';
import { getGoldpartnerRoutes } from './goldpartnerRoutes';
import { getFulfillmentRoutes } from './fulfillmentRoutes';

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});
Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/login',
        alias: '/phw/login',
        name: 'login',
        props: true,
        component: () => import(/* webpackChunkName: "SignIn" */ '@/pages/SignIn.vue')
    },
    {
        path: serverData.routesPrefix,
        redirect: 'login',
        component: AbstractView,
        children: [
            {
                path: 'create-pass',
                alias: 'phw/create-pass',
                name: 'create-pass',
                props: true,
                component: () => import(/* webpackChunkName: "SignIn" */ '@/pages/SignIn.vue')
            },
            {
                path: 'registration',
                alias: 'phw/registration',
                name: 'registration',
                component: () => import(/* webpackChunkName: "RegistrationPage" */ '@/pages/registration/RegistrationPage.vue')
            },
            {
                path: 'registration-success',
                alias: 'phw/registration-success',
                name: 'registration-success',
                component: () => import(/* webpackChunkName: "RegistrationSuccess" */ '@/pages/registration/RegistrationSuccess.vue')
            },
            {
                path: 'partner-privacy-policy',
                alias: 'phw/partner-privacy-policy',
                name: 'partner-privacy-policy',
                component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/pages/privacyPolicy/PrivacyPolicy.vue'),
                meta: {
                    /* We are temporarily preventing this page from being accessible to business units that have not yet defined their legal texts */
                    requiresBusinessUnit: [BusinessUnit.DOCMORRIS]
                }
            },
            ...getMarketplaceRoutes(),
            ...getMyAccountRoutes(),
            ...getGoldpartnerRoutes(),
            ...getFulfillmentRoutes(),
            { path: '*', redirect: { name: 'marketplace/sales' } }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

router.beforeEach(
    multiguard([
        loadPreferredLanguageLocale,
        isUserLoggedIn,
        loadSellerLanguageLocales,
        isUserAllowed,
        isBusinessUnitValid,
        isRoutePendingToMigrate,
        checkConsentForTracking,
        handleZendeskWebWidget
    ])
);

router.afterEach(loadTours);

export default router;
